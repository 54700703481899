import { connect, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { getUser } from "../../utils/User";
import Button from "../../components/buttons/Button";
import SearchButton from "../../components/buttons/SearchButton";
import SearchButtonDefault from "../../components/buttons/SearchButtonDefault";
import SubItem from "./SubItem";
import Data from "./Data";
import Map from "./Map";
import ModalFilter from "./FilterILIModal";
import DataMapBaseLayout from "../../components/layout/data_map";
import InfiniteScroll from "react-infinite-scroll-component";

import moment from "moment";

// service
import iliService from "../../store/actions/infrastructure_leakage_index";

const Index = ({ pending }) => {
  const [activeView, setActiveView] = useState("data");
  const [activeILI, setActiveILI] = useState(null);
  const [ILI, setILI] = useState([]);
  const [searchDMA, setSearchDMA] = useState("");
  const [searchTable, setSearchTable] = useState("");
  const [sortTable, setSortTable] = useState("");
  const [showModalFilter, setShowModalFilter] = useState(false);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    pdam_id: "",
    start_date: "",
    end_date: "",
  });
  const currentUser = getUser().user;
  const dataMapBaseLayoutRef = useRef();
  const [currentSubitemPage, setCurrentSubitemPage] = useState(1);
  const [totalSubitemData, setTotalSubitemData] = useState(1);
  const [maxSubitemPage, setMaxSubitemPage] = useState(1);

  // map vars
  // --------
  const [currentCenter, setCurrentCenter] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(10);

  const flyToZoom = 18;

  const showContentSubItem = (data) => {
    setActiveILI(data);
    setCurrentCenter([data.latitude, data.longitude]);
    setCurrentZoom(flyToZoom);
  };

  const handleSearch = (value) => {
    setSearchTable(value);
  };

  const handleSearchDMA = (value) => {
    setSearchDMA(value);
  };

  useEffect(() => {
    setActiveILI(null);
    activeView === "peta" && getIli(true);
  }, [currentUser?.pdam?.id, filter, searchDMA, activeView]);

  const getIli = (refresh = false) => {
    new Promise((resolve) => {
      let param = {
        draw: 1,
        length: 10,
        page: refresh ? 1 : currentSubitemPage + 1,
        pdam_id: currentUser?.pdam?.id,
        start_date:
          filter?.start_date !== ""
            ? moment(filter?.start_date).format("YYYY-MM-DD")
            : "",
        end_date:
          filter?.end_date !== ""
            ? moment(filter?.end_date).format("YYYY-MM-DD")
            : "",
        search_text: searchDMA,
      };
      dispatch(iliService.get(param, resolve));
    }).then((res) => {
      setTotalSubitemData(res?.totalCount);
      setMaxSubitemPage(Math.ceil(res?.totalCount / 10));
      if (refresh) {
        setCurrentSubitemPage(1);
        setILI(res?.data);
        return;
      }
      setCurrentSubitemPage(currentSubitemPage + 1);
      setILI(ILI.concat(res?.data));
    });
  };

  useEffect(() => {
    dataMapBaseLayoutRef.current.getMap()?.flyTo(currentCenter, flyToZoom);
  }, [currentCenter]);

  const dataHeaderContent = (
    <>
      <SearchButton
        placeholder="Masukkan Nama DMA"
        toggle={handleSearch}
        optClass="mr-3"
      />
      <Button
        title="Filter"
        size="sm"
        rounded={true}
        variant="button-light"
        onClick={() => setShowModalFilter(true)}
      />
    </>
  );

  const mapHeaderContent = <></>;

  const dataContent = (
    <Data search_text={searchTable} filter={filter} sort={sortTable} />
  );

  const mapContent = <Map activeILI={activeILI} ILI={ILI} />;

  return (
    <div className="menu-container pr-0">
      <div className={activeView === "peta" ? "grid-header" : ""}>
        {activeView === "peta" && (
          <div className="side-menu">
            <div className="menu-title bordered-b">
              <div className="row">
                <div className="col">
                  <SearchButtonDefault
                    placeholder="Cari DMA"
                    trigger={(value) => handleSearchDMA(value)}
                  />
                </div>
              </div>
            </div>
            <div
              id="subItemContainer-ili"
              style={{ overflow: "auto", height: "calc(100vh - 134px)" }}
            >
              <InfiniteScroll
                dataLength={ILI.length} //This is important field to render the next data
                next={getIli}
                scrollableTarget="subItemContainer-ili"
                hasMore={currentSubitemPage - 1 < maxSubitemPage} // currentSubitemPage-1 < maxSubitemPage
                loader={
                  pending ? (
                    <div
                      style={{
                        width: "100",
                        textAlign: "center",
                        padding: "15px",
                      }}
                    >
                      Memuat...
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100",
                        textAlign: "center",
                        padding: "15px",
                      }}
                    >
                      Data tidak ditemukan
                    </div>
                  )
                }
                endMessage={
                  ILI.length > 0 ? (
                    <p style={{ textAlign: "center" }}>
                      <b>You have seen it all</b>
                    </p>
                  ) : (
                    <div
                      style={{
                        width: "100",
                        textAlign: "center",
                        padding: "15px",
                      }}
                    >
                      Data tidak ditemukan
                    </div>
                  )
                }
              >
                {ILI
                  // .filter((item) =>
                  //   ("DMA " + item.dma_name)
                  //     .toLowerCase()
                  //     .includes(searchDMA.toLowerCase())
                  // )
                  .map((item, idx) => (
                    <SubItem
                      key={item.id}
                      title={"DMA " + item.dma_name}
                      // subtitle={item.dma_region_name || "-"}
                      toggle={() => showContentSubItem(item)}
                      active={item.id === activeILI?.id}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          </div>
        )}
        <DataMapBaseLayout
          ref={dataMapBaseLayoutRef}
          title={`Infrastructure Leakage Index`}
          dataContent={dataContent}
          mapContent={mapContent}
          dataHeaderContent={dataHeaderContent}
          mapHeaderContent={mapHeaderContent}
          initialCenter={currentCenter}
          initialZoom={currentZoom}
          onChangeView={(view) => setActiveView(view)}
        />
        {activeView === "data" && (
          <div className="main-fill-content">
            <ModalFilter
              show={showModalFilter}
              toggle={() => setShowModalFilter(false)}
              filter={filter}
              toggleFilter={setFilter}
            />
          </div>
        )}
        {/* content elem here */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ ILI: { pending } }) => {
  return { pending };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
